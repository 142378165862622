import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import i18n from '@/i18n';
import EVENTS from '@/utils/Constants/Events';
import LanguageSelectorController from '@/utils/Controllers/LanguageSelectorController';

const CountryPopupComponent = ({ title, html }) => {
  const handleClose = () => {
    const popup = document.querySelector('.dln-popup.dln-popup--open');
    if (popup) {
      DLG.EVE.emit(EVENTS.MODAL.CLOSE, { popup });
    }
  };

  useEffect(() => {
    DLG.EVE.emit(EVENTS.MODAL.OPEN, null, {
      customPopupSelector: '#js-default-dln-popup',
      fullSize: 'both',
      scrollOrigin: 'page',
    });
    LanguageSelectorController();
  }, []);

  return (
    <div className='dln-countrySelection--modal'>
      <div className='dln-popup__head dln-popup__head--bigTitle'>
        <button
          className='dln-btnIcon dln-btnIcon--close'
          aria-label='Close'
          type='button'
          onClick={handleClose}
        >
          <span className='dln-btnIcon__wrap'>
            <i className='dln-icn dln-icn--iconfont'>J</i>
          </span>
        </button>
        <h2 className='dln-popup__headTitle'>{title}</h2>
      </div>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

CountryPopupComponent.propTypes = {
  title: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
};

class CountrySelectionPopupLoader {
  static init() {
    const cta = document.getElementById('js-cta-country-selection-popup');
    if (cta) {
      cta.addEventListener('click', () => CountrySelectionPopupLoader.load());
      cta.addEventListener('keydown', e => {
        if (e.code === 'Enter' || e.code === 'Space') {
          e.preventDefault();
          e.stopPropagation();
          CountrySelectionPopupLoader.load();
        }
      });
    }
  }

  static load() {
    const entry = document.getElementById('js-r-entry-country-selection-popup');
    if (entry) {
      const { title } = entry.dataset;
      const html = entry.innerHTML;
      i18n.loadNamespaces(['common'], () => {
        ReactDOM.render(
          <I18nextProvider i18n={i18n}>
            <Suspense fallback={<div />}>
              <CountryPopupComponent title={title} html={html} />
            </Suspense>
          </I18nextProvider>,
          document.querySelector(
            '#js-default-dln-popup #js-dln-popup__wrapper',
          ),
        );
      });
    }
  }
}

export default CountrySelectionPopupLoader;
