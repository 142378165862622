import Cookie from 'js-cookie';
import { isTrue } from '@/utils/Common/Booleans';
import CONST from '@/utils/Constants/General';
import { pushGtmChatOpen } from '@/utils/Controllers/GTM/Operations/Chat';
import LoadJS from '../LoadJS';

const SNAP_ENGAGE_URL = '//storage.googleapis.com/code.snapengage.com/js/';
const CRM_NAME = 'CRM_name';
const CRM_SURNAME = 'CRM_surname';
const CRM_EMAIL = 'CRM_email';
const CRM_GIGYAUID = 'CRM_gigyaUid';
export default class SnapEngageController {
  static init() {
    if (
      isTrue(DLG.snapEngage.isVisible) &&
      isTrue(DLG.snapEngage.isEnabled) &&
      DLG.snapEngage.widgetId
    ) {
      LoadJS({
        src: `${SNAP_ENGAGE_URL}${DLG.snapEngage.widgetId}.js`,
        onLoadCb: SnapEngageController.onLoad,
      });
    }
  }

  static onLoad() {
    const { customerUid, customerFirstName } = DLG.snapEngage;

    // set customer info if available
    if (customerUid) {
      SnapEngage.setUserEmail(customerUid);
      SnapEngage.setUserName(customerFirstName);
      SnapEngage.setCallback('Open', SnapEngageController.onOpenCb);
    }

    SnapEngageController.openCustomProactiveChat();
  }

  static onOpenCb() {
    // on chat open, set custom fields in order to see them in the chat form.
    const {
      customerId,
      customerUid,
      customerFirstName,
      customerLastName,
    } = DLG.snapEngage;
    SnapEngage.setCustomField(CRM_NAME, customerFirstName);
    SnapEngage.setCustomField(CRM_SURNAME, customerLastName);
    SnapEngage.setCustomField(CRM_EMAIL, customerUid);
    SnapEngage.setCustomField(CRM_GIGYAUID, customerId);
    pushGtmChatOpen();
  }

  static openCustomProactiveChat() {
    const { isProactiveChatEnabled, proactiveChatDelay = 1 } = DLG.snapEngage;
    // if proactive chat is enabled set a timeout and open the chat
    if (
      !Cookie.get(CONST.COOKIE.SNAPENGAGE.PROACTIVE) &&
      isTrue(isProactiveChatEnabled)
    ) {
      setTimeout(() => {
        if (SnapEngage.getAgentStatus()) {
          SnapEngage.startLink();
          Cookie.set(CONST.COOKIE.SNAPENGAGE.PROACTIVE, true);
        }
      }, proactiveChatDelay * 1000);
    }
  }
}
