import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import i18n from '@/i18n';
import EVENTS from '@/utils/Constants/Events';
import NewsletterSupportController from '@/utils/Controllers/Gigya/NewsletterSupportController';
import GigyaController from '@/utils/Controllers/Gigya/GigyaController';

const NewsletterComponent = ({ html }) => {
  const gigyaCallback = () => {
    GigyaController.loadScreen(NewsletterSupportController.screenSetConfig);
  };

  useEffect(() => {
    DLG.EVE.emit(EVENTS.MODAL.OPEN, null, {
      customPopupSelector: '#js-default-dln-popup',
      desktopAlign: 'center',
      fullSize: 'true',
      maxWidth: 'large',
      gigyaCallback,
    });
    return () => {
      document.getElementById('js-newsletter-popup-hidden').id =
        'js-newsletter-popup';
      const { outerHTML } = document.getElementById('js-subscription-flow');
      document.getElementById('js-newsletter-popup').innerHTML = outerHTML;
      NewsletterSupportController.refreshPopUp();
    };
  }, []);

  return (
    <div
      className='dln-subscription-flow'
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

NewsletterComponent.propTypes = {
  html: PropTypes.string.isRequired,
};

class NewsletterPopupLoader {
  static init() {
    const cta = document.getElementById('js-show-newsletter-popup');
    const digiohPopupEnabled =
      cta?.getAttribute('data-digioh-newsletter-enabled') === 'true';
    if (cta && !digiohPopupEnabled) {
      cta.addEventListener('click', () => NewsletterPopupLoader.load());
    }
  }

  static load() {
    const entry = document.getElementById('js-newsletter-popup');
    const news = document.getElementById('js-default-dln-popup');
    if (news) news.classList.add('newsletter-popup-opened');
    if (entry) {
      const html = entry.innerHTML;
      entry.id = 'js-newsletter-popup-hidden';
      entry.innerHTML = '';
      i18n.loadNamespaces(['common'], () => {
        ReactDOM.render(
          <I18nextProvider i18n={i18n}>
            <Suspense fallback={<div />}>
              <NewsletterComponent html={html} />
            </Suspense>
          </I18nextProvider>,
          document.querySelector(
            '#js-default-dln-popup #js-dln-popup__wrapper',
          ),
        );
      });
    }
  }
}

export default NewsletterPopupLoader;
