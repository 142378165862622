import React, { useEffect, useState } from 'react';
import CONST from '@/utils/Constants/General';
import EVENTS from '@/utils/Constants/Events';

const AjaxLoadingSpinner = () => {
  const [visibleClass, setVisibleClass] = useState('');
  const [ids, setIds] = useState([]);

  const openLoader = uuid => {
    const timer = setTimeout(() => {
      setVisibleClass('dln-loading-spinner--visible');
    }, CONST.SPLASH.LOADER.TIMEOUT);
    setIds(prevState => [...prevState, { id: uuid, timer }]);
  };

  const closeLoader = uuid => {
    setIds(prevState =>
      prevState.filter(item => {
        if (item.id === uuid) {
          clearTimeout(item.timer);
        }
        return item.id !== uuid;
      }),
    );
  };

  useEffect(() => {
    DLG.EVE.on(EVENTS.SPLASH.LOADER.OPEN, openLoader);
    DLG.EVE.on(EVENTS.SPLASH.LOADER.CLOSE, closeLoader);
  }, []);

  useEffect(() => {
    if (ids.length === 0) {
      setVisibleClass('');
    }
  }, [ids]);

  return (
    <div className={`dln-loading-spinner ${visibleClass}`}>
      <div className='loader'>Loading...</div>
    </div>
  );
};

export default AjaxLoadingSpinner;
