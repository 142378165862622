import { initVideoResponsive } from '@/braun/Controllers/VideoComponentController';

export default () => {
  const playButton = document.getElementsByClassName(
    'dln-recipe-details__play-button',
  )[0];
  const image = document.getElementsByClassName(
    'dln-recipe-details__image-container',
  )[0];
  const video = document.getElementsByClassName(
    'dln-recipe-details__videoBgContainer',
  )[0];
  const videos = document.getElementsByTagName('video');

  if (playButton) {
    playButton.addEventListener('click', () => {
      initVideoResponsive(videos);
      image.classList.add('hide');
      video.classList.add('show');
    });
  }
};
