/* eslint-disable */
import { initCarousel } from '@/utils/Controllers/CarouselController';

const handleBrowseByGradients = () => {
  const items = [...document.getElementsByClassName('dln-browse-by__link')];
  const tabGradients = [...document.getElementsByClassName('dln-browse-by__gradient')];
  const observerLastTarget = items[items.length - 1];
  const observerFirstTarget = items[0];
  let observer = new IntersectionObserver(callback, { threshold: 0.9 });
  observer.observe(observerLastTarget);
  observer.observe(observerFirstTarget);

  function callback(entries, observer) {
    entries.forEach(entry => {
      if (entry.target === observerFirstTarget) {
        if (!entry.isIntersecting) {
          handleGradient(0, 'add');
        } else {
          handleGradient(0, 'remove');
        }
      }
      else if (entry.target === observerLastTarget) {
        if (entry.isIntersecting) {
          handleGradient(1, 'remove')
        } else {
          handleGradient(1, 'add')
        }
      }
    })
  }

  function handleGradient(gradientTarget, gradientAction) {
    if (gradientAction == 'add') {
      tabGradients[gradientTarget].classList.add('-visible');
    } else if (gradientAction == 'remove') {
      tabGradients[gradientTarget].classList.remove('-visible');
    }
  }
};

export default () => {
  const browseBySwiper = document.getElementsByClassName('dln-browse-by swiper-container')[0];
  if (browseBySwiper) {
    initCarousel(browseBySwiper, {
      slidesPerViewXl: 'auto',
      slidesPerViewL: 'auto',
      slidesPerViewM: 'auto',
      slidesPerViewXs: 'auto',

      spaceBetweenXl: 0,
      spaceBetweenL: 35,
      spaceBetweenM: 35,
      spaceBetweenXs: 35
    });

    handleBrowseByGradients();
  }
}