import PropTypes from 'prop-types';
import React from 'react';

const NotificationItemMinimal = ({ id, type, message, onDelete }) => (
  <div className={`dln-alert dln-alert--minimal dln-alert--${type}`}>
    <div className='dln-popup__head dln-popup__head--circleClose'>
      <h2 className='dln-popup__headTitle dln-alert__title'>
        <i className='dln-icn dln-alert__icon' />
        <span
          className='dln-alert__text'
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </h2>
      <button
        className='dln-btnIcon dln-btnIcon--close dln-btnIcon--circleClose'
        type='button'
        onClick={() => onDelete(id)}
      >
        <span className='dln-btnIcon__wrap'>
          <i className='dln-icn dln-icn--iconfont'>g</i>
        </span>
      </button>
      <span className='dln-popup__headLine' />
    </div>
  </div>
);

NotificationItemMinimal.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default NotificationItemMinimal;
