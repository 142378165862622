import { isTrue } from '@/utils/Common/Booleans';
import getShowStripe from '@/api/head/showStripe';
import { hasSameCountryNextUrl } from '@/utils/Common/Utils';
import { initAccordions } from '@/utils/Controllers/AccordionController';

const parseFlag = evt => {
  evt.preventDefault();
  const flag = evt.currentTarget;
  let { contextPath } = flag.dataset;
  const { isRolledOut } = flag.dataset;
  contextPath = contextPath ? `/${contextPath}` : '/en';
  const nextUrl = window.location.pathname.replace(
    DLG.config.encodedContextPath,
    contextPath,
  );
  const handleSuccess = res => {
    const hasSameUrl = hasSameCountryNextUrl(nextUrl, res.responseURL);
    window.location.href = hasSameUrl ? nextUrl : contextPath;
  };
  const handleFail = () => {
    window.location.href = contextPath;
  };

  if (!isTrue(isRolledOut)) {
    handleFail();
    return;
  }

  getShowStripe({
    url: nextUrl,
    successCB: handleSuccess,
    failCB: handleFail,
  });
};

export default () => {
  const flags = Array.from(document.querySelectorAll('.js-dln-flag__listLink'));
  flags.forEach(flag => {
    flag.addEventListener('click', parseFlag);
  });
  initAccordions();
};
