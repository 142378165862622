import { HEAD } from '@/api';

export default async ({ url, config, successCB, failCB }) => {
  try {
    const { request } = await HEAD(url, config);
    return (typeof successCB === 'function' && successCB(request)) || request;
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};
