/* eslint-disable import/prefer-default-export */
import GTM from '@/utils/Controllers/GTM/Constants';
import { push } from '@/utils/Controllers/GTM/Utils';

export const pushGtmChatOpen = () => {
  push({
    event: GTM.EVENT.CHAT,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.CHAT,
    eventLabel: GTM.EVENT.LABEL.OPEN,
  });
};
