import GTM from '@/utils/Controllers/GTM/Constants';
import { push } from '@/utils/Controllers/GTM/Utils';

const CLASS_LABEL_SELECTOR = 'js-filter-label';
const CLASS_DETAIL_SELECTOR = 'js-filter-detail';
const CLASS_REMOVE_SELECTOR = 'js-filter-remove';
const CLASS_REMOVEALL_SELECTOR = 'js-filter-removeall';
const CLASS_SORTBY_SELECTOR = 'js-sortby-filter';

const filterData = {
  event: GTM.FILTERS.FILTER,
  eventCategory: GTM.FILTERS.EVENT_CATEGORY,
};

const pushGtmFilter = payload => {
  push(payload);
};

const handleFilter = evt => {
  const el = evt.target;
  const elParent = el.closest(`.${CLASS_LABEL_SELECTOR}`);

  switch (true) {
    case el.classList.contains(CLASS_DETAIL_SELECTOR):
      filterData.eventLabel = elParent.dataset.filterLabel;
      filterData.eventDetail = el.dataset.filterDetail;
      filterData.eventAction = GTM.FILTERS.FILTER_REMOVE;

      if (el.checked) {
        filterData.eventAction = GTM.FILTERS.FILTER_APPLY;
      }

      pushGtmFilter(filterData);
      break;

    case el.classList.contains(CLASS_REMOVE_SELECTOR):
      filterData.eventAction = GTM.FILTERS.FILTER_REMOVE;
      filterData.eventLabel = el.dataset.filterLabel;
      filterData.eventDetail = el.dataset.filterDetail;
      pushGtmFilter(filterData);
      break;

    case el.classList.contains(CLASS_REMOVEALL_SELECTOR):
      delete filterData.eventDetail;
      filterData.eventAction = GTM.FILTERS.FILTER_REMOVE;
      filterData.eventLabel = GTM.FILTERS.FILTER_REMOVE_ALL;
      pushGtmFilter(filterData);
      break;

    case el.classList.contains(CLASS_SORTBY_SELECTOR):
      delete filterData.eventDetail;
      filterData.eventAction = GTM.FILTERS.ORDER_BY;
      filterData.eventLabel = el.dataset.sortLabel;
      pushGtmFilter(filterData);
      break;

    default:
      break;
  }
};

const handleSortChange = evt => {
  const el = evt.target;
  const searchSortSelect = document.querySelector(
    '.dln-select--js-filter-sort #sortSelect',
  );

  if (el === searchSortSelect) {
    delete filterData.eventDetail;
    filterData.eventAction = GTM.FILTERS.ORDER_BY;
    filterData.eventLabel = el.value;

    pushGtmFilter(filterData);
  }
};

export default () => {
  document.addEventListener('click', handleFilter);
  document.addEventListener('change', handleSortChange);
};
