import GigyaController from '@/utils/Controllers/Gigya/GigyaController';
import EVENTS from '@/utils/Constants/Events';
import CONST from '@/utils/Constants/General';
import { isTrue } from '@/utils/Common/Booleans';
import pushGtmNewsletterSignup from '@/utils/Controllers/GTM/Operations/NewsLetterSignUp';
import {
  bindGigyaToggleContent,
  bindInfoSubscription,
} from '@/utils/Controllers/Gigya/Utils';
// import api
import apiGigyaLiteRegistrationInfo from '@/api/post/gigyaLiteRegistrationInfo';

const CONTAINER_ID = 'js-dlg-gigya-newsletter-container';
const START_SCREEN = 'gigya-newsletter-screen';
const SCREENSET = 'Default-LiteRegistration';

export default class GigyaBasicFormController {
  static screenSetConfig = {
    screenSet: SCREENSET,
    startScreen: START_SCREEN,
    containerID: CONTAINER_ID,
    onBeforeScreenLoad: GigyaBasicFormController.onBeforeScreenLoad,
    onAfterScreenLoad: GigyaBasicFormController.onAfterScreenLoad,
    onBeforeSubmit: GigyaBasicFormController.onBeforeSubmit,
    onAfterSubmit: GigyaBasicFormController.onAfterSubmit,
  };

  static init() {
    const container = document.getElementById(
      'js-dlg-gigya-newsletter-container',
    );
    if (container === null) return;

    GigyaController.loadScreen(GigyaBasicFormController.screenSetConfig);
  }

  static onAfterScreenLoad(event) {
    GigyaController.onAfterScreenLoad(event);
    bindGigyaToggleContent(
      event.sourceContainerID,
      '#gigya-subscribe-with-email-form',
    );
    bindInfoSubscription(
      event.sourceContainerID,
      '#gigya-subscribe-with-email-form',
    );
    const gigyaTextbox = document.getElementById('gigya-textbox-email');
    if (DLG.config.isLogged) {
      gigyaTextbox.disabled = true;
    }
  }

  static onBeforeSubmit() {
    const customCheckbox = document.querySelector(
      '#js-dlg-gigya-newsletter-container .gigya-container-enabled .gigya-input-checkbox',
    );
    const mandatoryConsent = document.querySelector(
      '#js-dlg-gigya-newsletter-container',
    );
    const consent = mandatoryConsent.dataset.consentMandatory;

    if (
      (isTrue(consent) || consent === null) &&
      customCheckbox !== null &&
      !isTrue(customCheckbox.checked)
    ) {
      const message = document
        .getElementById(CONTAINER_ID)
        .getAttribute('data-checkbox-error');
      DLG.EVE.emit(EVENTS.NOTIFICATION.ADD.ERROR, { message });
      return false;
    }
    return true;
  }

  static hanldeLightRegistration(response, event, callback) {
    // if the response has the UID means that is a registration, if not, it will retrieving info from logged user
    const gigyaUID = event.response.UID ? event.response.UID : response.UID;
    // get the locale
    const lang = gigya.thisScript.lang.originalLang;
    // get the country code
    const countryCode = gigya.thisScript.globalConf.countryCodeMapping[lang];
    // get the consent field related to the country code
    const siteConsent = gigya.thisScript.globalConf.consentMapping[countryCode];
    // get the boolean value of the consent checkbox
    const isChecked = document.querySelector(
      '#js-dlg-gigya-newsletter-container .gigya-container-enabled .gigya-input-checkbox',
    ).checked;
    // get user email
    const { email } = event.profile;
    // campaignTag
    const campaignTag = document
      .getElementById(CONTAINER_ID)
      .getAttribute('data-campaign-tag');
    // origin
    const origin = event.data.origin || CONST.GIGYA.ORIGIN.ECOMMERCE;
    apiGigyaLiteRegistrationInfo({
      info: {
        gigyaUID,
        siteConsent,
        lang,
        countryCode,
        isChecked,
        email,
        campaignTag,
        origin,
      },
      successCB: () => callback(gigyaUID, campaignTag, email),
    });
  }

  static gigyaLiteRegistrationInfo(callback, event) {
    // GET USER INFO
    // get the user UID
    gigya.accounts.getAccountInfo({
      callback: response => {
        GigyaBasicFormController.hanldeLightRegistration(
          response,
          event,
          callback,
        );
      },
    });
  }

  static onAfterSubmit(event) {
    GigyaController.onAfterSubmit();
    const successCB = (gigyaUID, campaignTag, email = '') => {
      const message = document
        .getElementById(CONTAINER_ID)
        .getAttribute('data-form-success');
      DLG.EVE.emit(EVENTS.NOTIFICATION.ADD.SUCCESS, { message });
      // gtm
      pushGtmNewsletterSignup(gigyaUID, campaignTag, email);
    };
    GigyaBasicFormController.gigyaLiteRegistrationInfo(successCB, event);
  }

  static onBeforeScreenLoad() {
    const newsletterBtn = document.getElementById('js-show-newsletter-popup');
    newsletterBtn.addEventListener('click', () => {
      let interval;
      let intervalC = 0;
      const intervalM = 30;
      (() => {
        interval = setInterval(() => {
          const form = document.getElementById(
            'gigya-subscribe-with-email-form',
          );
          if (intervalC > intervalM) {
            clearInterval(interval);
          } else if (form !== null) {
            clearInterval(interval);
            const inputName = form.querySelectorAll(
              '[name = "profile.firstName"]',
            );
            const inputSurname = form.querySelectorAll(
              '[name = "profile.lastName"]',
            );
            inputName[0].setAttribute('maxlength', '40');
            inputSurname[0].setAttribute('maxlength', '40');
          }
          intervalC += 1;
        }, 1000);
      })();
    });
  }
}
