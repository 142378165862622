export default () => {
  const accordionArray = ['.dln-recipe-single__col-gray'];

  accordionArray.map(accordionClass => {
    const accordion = document.querySelector(accordionClass);
    return (
      accordion &&
      accordion.addEventListener('click', event => {
        const expander = event.target.classList.contains(
          'dln-recipe-single__accordion',
        )
          ? event.target
          : event.target.closest('.dln-recipe-single__accordion') || null;

        if (event.target && expander) {
          const accordionOpen = document.querySelector(
            `${accordionClass} .active`,
          );
          const eventIsActive = expander.classList.contains('active');

          if (accordionOpen !== null) {
            accordionOpen.classList.remove('active');
          }

          if (!eventIsActive) {
            expander.classList.add('active');
          }
        }
      })
    );
  });
};
