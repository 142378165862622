import React, { useState, useEffect } from 'react';
import InputText from '@/components/atoms/InputText';
import { useTranslation } from 'react-i18next';

const CONVERT_RATE = 7.5345;

const CurrencyConverter = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('0');
  const [convertedValue, setConvertedValue] = useState('0');

  useEffect(() => {
    const converted =
      (
        value
          .toString()
          .replace(',', '.')
          .replace(/[^0-9.]/g, '') * CONVERT_RATE
      ).toFixed(2) || 0;

    setConvertedValue(converted.toString());
  }, [value]);

  const updateInputValue = e => {
    const regex = /^([0-9]+[.,]?[0-9]*)?$/;
    if (regex.test(e.target.value)) {
      setValue(e.target.value);
    }
  };

  return (
    <div className='dln-currency-converter'>
      {!isOpen && (
        <button
          type='button'
          className='dln-currency-converter__button'
          onClick={() => setIsOpen(true)}
        />
      )}
      {isOpen && (
        <div className='dln-currency-converter__widget'>
          <button
            type='button'
            className='dln-currency-converter__widget-close dln-btnIcon dln-btnIcon--close'
            onClick={() => setIsOpen(false)}
          >
            <i className='dln-icn dln-icn--iconfont dln-icn--J' data-glyph='J'>
              J
            </i>
          </button>
          <div className='dln-currency-converter__widget-wrapper'>
            <p className='dln-currency-converter__widget-title'>
              {t('currencyConverter:currencyConverter.title')}
            </p>
            <div className='dln-currency-converter__widget-inner-wrapper'>
              <div className='dln-currency-converter__input-inner-label --more-padding'>
                {t('currencyConverter:currencyConverter.firstCurrency')}
              </div>
              <InputText
                value={value}
                onChange={updateInputValue}
                id='amount'
                label={t('currencyConverter:currencyConverter.amountLabel')}
              />
            </div>
            <div className='dln-currency-converter__widget-inner-wrapper'>
              <div className='dln-currency-converter__input-inner-label'>
                {t('currencyConverter:currencyConverter.secondCurrency')}
              </div>
              <InputText
                value={convertedValue}
                onChange={e => e.target.value}
                id='amount-second'
                label={t('currencyConverter:currencyConverter.convertedLabel')}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CurrencyConverter;
