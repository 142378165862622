import GTM from '@/utils/Controllers/GTM/Constants';
import { push } from '@/utils/Controllers/GTM/Utils';

const COFFEE_LOUNGE_CLASS = 'js-GTM-coffee-lounge';
const CLASS_DETAIL_SELECTOR_MENU = 'js-GTM-menu';
const CLASS_DETAIL_SELECTOR_NEWS = 'js-GTM-news';
const CLASS_DETAIL_SELECTOR_CHANNEL = 'js-GTM-channel';
const CLASS_DETAIL_SELECTOR_CATEGORY = 'js-GTM-category';

const coffeeLoungeData = {
  event: GTM.COFFEE_LOUNGE.EVENT,
  eventCategory: GTM.COFFEE_LOUNGE.EVENT_CATEGORY,
  eventAction: GTM.COFFEE_LOUNGE.EVENT_ACTION,
};

const pushGtmCoffeeLounge = payload => {
  push(payload);
};

const handleCoffeeLounge = evt => {
  let el = evt.target;

  if (el.tagName.toLowerCase() !== 'a') {
    el = el.closest('a');
  }

  if (el && el.classList.contains(COFFEE_LOUNGE_CLASS)) {
    switch (true) {
      case el.classList.contains(CLASS_DETAIL_SELECTOR_MENU):
        coffeeLoungeData.eventLabel = GTM.COFFEE_LOUNGE.EVENT_LABEL_MENU;
        pushGtmCoffeeLounge(coffeeLoungeData);
        break;

      case el.classList.contains(CLASS_DETAIL_SELECTOR_NEWS):
        coffeeLoungeData.eventLabel = GTM.COFFEE_LOUNGE.EVENT_LABEL_NEWS;
        pushGtmCoffeeLounge(coffeeLoungeData);
        break;

      case el.classList.contains(CLASS_DETAIL_SELECTOR_CHANNEL):
        coffeeLoungeData.eventLabel = GTM.COFFEE_LOUNGE.EVENT_LABEL_CHANNEL;
        pushGtmCoffeeLounge(coffeeLoungeData);
        break;

      case el.classList.contains(CLASS_DETAIL_SELECTOR_CATEGORY):
        coffeeLoungeData.eventLabel = GTM.COFFEE_LOUNGE.EVENT_LABEL_CATEGORY;
        pushGtmCoffeeLounge(coffeeLoungeData);
        break;

      default:
        break;
    }
  }
};

export default () => {
  document.addEventListener('click', handleCoffeeLounge);
};
