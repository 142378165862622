import GTM from '@/utils/Controllers/GTM/Constants';
import {
  push,
  getProductsGtmInfoForListing,
} from '@/utils/Controllers/GTM/Utils';
import { goToPage } from '@/utils/Common/Redirects';
import FindParentByClass from '@/utils/Common/FindParentByClass';

const CLASS_NAME_SELECTOR = 'js-gtm-product-click';

const pushGtmProductClick = (payload, list, url) => {
  push({
    event: GTM.EVENT.PRODUCT_CLICK,
    ecommerce: {
      click: { actionField: { list }, ...payload },
      eventCallback: goToPage(url),
    },
  });
};

const handleProductClick = evt => {
  const gtmProductClickTarget = FindParentByClass(
    evt.target,
    CLASS_NAME_SELECTOR,
  );
  if (gtmProductClickTarget != null) {
    evt.preventDefault();
    const productCode =
      gtmProductClickTarget.id || gtmProductClickTarget.dataset.productCode;
    const gtmInputContainer = document.getElementById(
      `${CLASS_NAME_SELECTOR}-${productCode}`,
    );
    if (gtmInputContainer != null) {
      const gtmProductInfo = getProductsGtmInfoForListing(gtmInputContainer);
      pushGtmProductClick(
        { products: [gtmProductInfo] },
        gtmInputContainer.dataset.listName,
        gtmProductClickTarget.href,
      );
    } else {
      goToPage(gtmProductClickTarget.href);
    }
  }
};

export default () => {
  document.addEventListener('click', handleProductClick);
};
