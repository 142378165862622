import { isTrue } from '@/utils/Common/Booleans';
import EVENTS from '@/utils/Constants/Events';
import GigyaController from '@/utils/Controllers/Gigya/GigyaController';
import {
  REGISTRATION_COMPLETION_SCREEN,
  VERIFICATION_SENT_SCREEN,
} from '@/utils/Controllers/Gigya/Constants';
import { reloadPage } from '@/utils/Common/Redirects';
import {
  setEntitlements,
  toggleContent,
  checkPreferences,
} from '@/utils/Controllers/Gigya/Utils';

const CONTAINER_ID = 'js-gigya-popup-container';

export default class GigyaRegistrationCompletionChangeCountryController {
  static dataIsMissing = false;

  static screenSetConfig = {
    startScreen: REGISTRATION_COMPLETION_SCREEN,
    containerID: CONTAINER_ID,
    onAfterScreenLoad:
      GigyaRegistrationCompletionChangeCountryController.onAfterScreenLoad,
    onBeforeScreenLoad:
      GigyaRegistrationCompletionChangeCountryController.onBeforeScreenLoad,
    onAfterSubmit:
      GigyaRegistrationCompletionChangeCountryController.onAfterSubmit,
    onSubmit: setEntitlements,
  };

  static init(config) {
    DLG.EVE.on(EVENTS.GIGYA.TOGGLE.CONTENT, toggleContent);
    GigyaRegistrationCompletionChangeCountryController.dataIsMissing = false;
    if (
      isTrue(DLG.gigya.hasGigyaCheckPreferences) &&
      DLG.config.isLogged &&
      !DLG.config.isSmartEdit
    ) {
      checkPreferences(null, () => {
        GigyaController.loadScreen({
          ...GigyaRegistrationCompletionChangeCountryController.screenSetConfig,
          ...config,
        });
      });
    }
  }

  static onBeforeScreenLoad(res) {
    const { nextScreen } = res;
    return VERIFICATION_SENT_SCREEN !== nextScreen;
  }

  static onAfterScreenLoad(res) {
    if (REGISTRATION_COMPLETION_SCREEN === res.currentScreen) {
      GigyaRegistrationCompletionChangeCountryController.dataIsMissing = true;
      DLG.EVE.emit(EVENTS.MODAL.OPEN, null, {
        customPopupSelector: '#js-gigya-popup',
        hasReactContent: true,
        desktopAlign: 'top',
      });
    }
    GigyaController.onAfterScreenLoad(res);
  }

  static onAfterSubmit(res) {
    GigyaController.onAfterSubmit();
    if (
      res.response.errorCode === 0 &&
      GigyaRegistrationCompletionChangeCountryController.dataIsMissing
    ) {
      reloadPage();
    }
  }
}
