import jsonp from 'jsonp';
import { notifyError } from '@/utils/Common/Notify';

const URL = `https://gethatch.com/5aeaeaf346e0fb0001be215c/affiliate/${DLG.hatch.brandId}/json?region=${DLG.hatch.siteCountry}&language=${DLG.hatch.isocode}&mpn=`;

const fetch = ({ productCode, handler, failCB }) => {
  try {
    // const urlTemp = `https://gethatch.com/5aeaeaf346e0fb0001be215c/affiliate/52789/json?region=IT&language=it&mpn=0X22211009`;
    // const urlTemp = `https://gethatch.com/5aeaeaf346e0fb0001be215c/affiliate/52463/json?region=IT&mpn=0132215212&`;
    // return jsonp(urlTemp, null, handler);
    return jsonp(URL + productCode, null, handler);
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};

export const prefetchHatch = ({ productCode, successCB, failCB }) => {
  const handler = (err, data) => {
    const hideHatch = err || data.iceleads_interface.status !== 200;
    successCB(hideHatch);
  };
  fetch({ productCode, handler, failCB });
};

export default async ({ productCode, successCB, failCB }) => {
  const handler = (err, data) => {
    if (err) {
      notifyError(err.message);
    } else if (data.iceleads_interface.status !== 200) {
      notifyError(data.iceleads_interface.status_desc);
    } else {
      successCB(data);
    }
  };
  fetch({ productCode, handler, failCB });
};
