import EVENTS from '@/utils/Constants/Events';
import FindParentByClass from '@/utils/Common/FindParentByClass';
import { notifySuccess } from '@/utils/Common/Notify';

import GigyaLoginPopupController from '@/utils/Controllers/Gigya/GigyaLoginPopupController';

// api
import isAuth from '@/api/get/isAuth';
import apiNotifyWishlistEntry from '@/api/post/notifyWishlistEntry';

// import gtm
import { pushGtmStockAlert } from '@/utils/Controllers/GTM/Operations/StockAlert';

export default class NotifyMeController {
  static init() {
    DLG.EVE.on(EVENTS.NOTIFY.PRODUCT, NotifyMeController.notifyProduct);
    document.addEventListener('click', NotifyMeController.bindAllNotifyMeCtas);
  }

  static bindAllNotifyMeCtas(evt) {
    const notifyMeCta = FindParentByClass(evt.target, 'js-notify-me');
    if (notifyMeCta && !notifyMeCta.disabled) {
      NotifyMeController.notifyMeCheck(notifyMeCta);
    }
  }

  static async notifyMeCheck(el) {
    const isLogged = await isAuth();

    const successCB = data => {
      if (data.attributes?.unsubscribed) {
        notifySuccess(data.message, successCB);
        el.innerText = el.dataset.notifyMeLbl;
      } else {
        notifySuccess(el.dataset.notifyMeSuccess, successCB);
        el.innerText = el.dataset.alreadyNotifiedLbl;
        pushGtmStockAlert();
      }
    };

    const notify = () =>
      NotifyMeController.notifyProduct({
        productCodePost: el.dataset.productCode,
        successCB,
      });

    if (isLogged) {
      notify();
    } else {
      GigyaLoginPopupController.init({
        successCB: notify,
        header: el.dataset.loginHeader,
        subHeader: el.dataset.loginSubHeader,
      });
    }
  }

  static notifyProduct({ productCodePost, successCB }) {
    const afterSuccess = data => {
      if (typeof successCB === 'function') successCB(data);
    };
    apiNotifyWishlistEntry({ productCodePost, successCB: afterSuccess });
  }
}
