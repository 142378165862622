import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from '@/i18n';
import { isHRSite } from '@/utils/Common/Booleans';
import CurrencyConverterComponent from '@/components/organisms/CurrencyConverter';

class CurrencyConverter {
  constructor() {
    this.entry = 'js-r-currency-converter';
  }

  init() {
    if (!isHRSite) {
      return;
    }

    i18n.loadNamespaces('currencyConverter');

    ReactDOM.render(
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={<div />}>
          <CurrencyConverterComponent />
        </Suspense>
      </I18nextProvider>,
      document.getElementById(this.entry),
    );
  }
}

export const currencyConverter = new CurrencyConverter();

export default {};
