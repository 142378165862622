// import operations
import Download from '@/utils/Controllers/GTM/Operations/Download';
import InternalSearch from '@/utils/Controllers/GTM/Operations/InternalSearch';
import ListingClick from '@/utils/Controllers/GTM/Operations/ListingClick';
import CoffeeLounge from '@/utils/Controllers/GTM/Operations/CoffeeLounge';
import Officina from '@/utils/Controllers/GTM/Operations/Officina';
import ListFilter from '@/utils/Controllers/GTM/Operations/ListFilter';

export default () => {
  Download();
  InternalSearch();
  ListingClick();
  CoffeeLounge();
  ListFilter();
  Officina();
};
