import { goToPage } from '@/utils/Common/Redirects';
import GTM from '@/utils/Controllers/GTM/Constants';
import { push } from '@/utils/Controllers/GTM/Utils';

const handleHeroClick = els => {
  els.forEach(el => {
    el.addEventListener('click', e => {
      e.preventDefault();
      push({
        event: GTM.EVENT.GENERIC,
        eventCategory: GTM.EVENT.CATEGORY.OFFICINA,
        eventAction: GTM.EVENT.ACTION.OFFICINA.HERO_CLICK,
        eventLabel: el.innerText,
        eventCallback: goToPage(el.href),
      });
    });
  });
};

export default () => {
  const els = document.querySelectorAll(
    '.js-officina-hero .del-textgroup__cta',
  );

  if (els.length === 0) return;

  handleHeroClick(els);
};
