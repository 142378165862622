import ReactDOM from 'react-dom';
import EVENTS from '@/utils/Constants/Events';
import FindParentByClass from '@/utils/Common/FindParentByClass';

const widthNormal = '456px';
const widthMedium = '584px';
const widthLarge = '720px';
let modalTriggerBtn = null;

class ModalController {
  static init() {
    DLG.EVE.on(EVENTS.MODAL.OPEN, ModalController.toggleModal);
    DLG.EVE.on(EVENTS.MODAL.CLOSE, ModalController.handleCloseModal);
  }

  static handleCloseModal({ evt, popup } = {}) {
    if (!evt && !popup) {
      popup = document.querySelector('.dln-popup.dln-popup--open');
    }
    if (!popup) {
      popup = FindParentByClass(evt.target, 'dln-popup');
    }
    ModalController.destroyModal(popup);
  }

  static initModal({
    popup,
    maxWidth,
    desktopAlign,
    mobileAlign,
    scrollOrigin,
    fullSize,
    animation,
  }) {
    if (!popup.dataset.noClose) {
      popup.addEventListener('keyup', ModalController.focusModal);
      popup.addEventListener('click', ModalController.toggleModal);
      document.addEventListener('keyup', ModalController.toggleModal);
    }

    const modal = popup;
    const popupContent = modal.children[0];
    const popupWrapper = modal.children[0].children[0];

    if (popupContent.querySelectorAll('.focus-edge').length < 1) {
      popupContent.insertAdjacentHTML(
        'afterbegin',
        '<span class="focus-edge" tabindex="0" style="width: 0; height: 0;"></span>',
      );
      popupContent.insertAdjacentHTML(
        'beforeend',
        '<span class="focus-edge" tabindex="0" style="width: 0; height: 0;"></span>',
      );
    }

    switch (maxWidth) {
      case 'normal':
        maxWidth = widthNormal;
        break;
      case 'medium':
        maxWidth = widthMedium;
        break;
      case 'large':
        maxWidth = widthLarge;
        break;
      default:
        maxWidth = widthNormal;
        break;
    }
    popupContent.style.maxWidth = maxWidth;

    switch (desktopAlign) {
      case 'top':
        desktopAlign = 'dln-popup--dTop';
        break;
      case 'center':
        desktopAlign = 'dln-popup--dCenter';
        break;
      case 'bottom':
        desktopAlign = 'dln-popup--dBottom';
        break;
      default:
        desktopAlign = 'dln-popup--dCenter';
        break;
    }
    modal.classList.add(desktopAlign);

    switch (mobileAlign) {
      case 'top':
        mobileAlign = 'dln-popup--mTop';
        break;
      case 'center':
        mobileAlign = 'dln-popup--mCenter';
        break;
      case 'bottom':
        mobileAlign = 'dln-popup--mBottom';
        break;
      default:
        mobileAlign = 'dln-popup--mCenter';
        break;
    }
    modal.classList.add(mobileAlign);

    switch (scrollOrigin) {
      case 'page':
        scrollOrigin = '';
        break;
      case 'modal':
        scrollOrigin = 'dln-popup--scrollModal';
        break;
      default:
        scrollOrigin = '';
        break;
    }

    const popupPaddingTop = window
      .getComputedStyle(modal)
      .getPropertyValue('padding-top');
    const popupPaddingBottom = window
      .getComputedStyle(modal)
      .getPropertyValue('padding-bottom');

    if (scrollOrigin !== '') {
      modal.classList.add(scrollOrigin);
      popupContent.style.maxHeight = `calc(100vh - ${popupPaddingTop} - ${popupPaddingBottom})`;
      popupWrapper.style.maxHeight = `calc(100vh - ${popupPaddingTop} - ${popupPaddingBottom})`;
    } else {
      modal.style.overflow = 'auto';
    }

    switch (fullSize) {
      case 'false':
        fullSize = 'dln-popup--noFullsize';
        break;
      case 'true':
        fullSize = 'dln-popup--fullsize';
        break;
      case 'both':
        fullSize = 'dln-popup--fullsize-desktop-mobile';
        break;
      default:
        fullSize = 'dln-popup--noFullsize';
        break;
    }
    modal.classList.add(fullSize);

    switch (animation) {
      case 'fadeIn':
        animation = 'dln-popup--fadeIn';
        break;
      case 'fadeInUp':
        animation = 'dln-popup--fadeInUp';
        break;
      case 'fadeInDown':
        animation = 'dln-popup--fadeInDown';
        break;
      case 'zoomIn':
        animation = 'dln-popup--zoomIn';
        break;
      default:
        animation = 'dln-popup--fadeIn';
        break;
    }
    modal.classList.add(animation);
  }

  static destroyModal(popup) {
    popup.removeEventListener('keyup', ModalController.focusModal);
    popup.removeEventListener('click', ModalController.toggleModal);
    document.removeEventListener('keyup', ModalController.toggleModal);
    popup.classList.remove('dln-popup--open');
    popup.classList.remove('dln-popup--visible');
    sessionStorage.setItem('index', 'undefined');

    document
      .getElementsByTagName('body')[0]
      .classList.remove('dln-popup--open', 'dln-popup--openAlert');

    setTimeout(() => {
      popup.removeAttribute('style');
      popup.className = 'dln-popup';
      popup.children[0].removeAttribute('style');
      popup.children[0].children[0].removeAttribute('style');
      popup.setAttribute('aria-hidden', 'true');
      popup.style.display = 'none';

      // removing content
      const wrapper = popup.querySelector('#js-dln-popup__wrapper');
      if (
        wrapper &&
        !ReactDOM.unmountComponentAtNode(
          popup.querySelector('#js-dln-popup__wrapper'),
        ) &&
        !popup.dataset.noRemoveContent
      ) {
        // if no react component found, remove content directly
        const popupdef = document.getElementsByClassName('dln-popup')[0];
        const sl = popupdef.getElementsByClassName('dln-popup__wrapper')[0]
          .children;
        for (let i = 0; i < sl.length; i += 1) {
          sl[i].remove();
        }
      }
    }, 300);
  }

  static focusModal(evt, { customPopupSelector = '.dln-popup' } = {}) {
    const evtPopup =
      evt &&
      evt.currentTarget &&
      evt.currentTarget.classList &&
      evt.currentTarget.classList.contains('dln-popup')
        ? evt.currentTarget
        : null;
    const popup = evtPopup || document.querySelectorAll(customPopupSelector)[0];

    const focusableElements = popup.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
    );

    if (evt.key === 'Tab') {
      if (evt.shiftKey && document.activeElement === focusableElements[0]) {
        evt.preventDefault();
        focusableElements[focusableElements.length - 2].focus();
      } else if (
        !evt.shiftKey &&
        document.activeElement ===
          focusableElements[focusableElements.length - 1]
      ) {
        evt.preventDefault();
        focusableElements[1].focus();
      }
    }
  }

  static toggleModal(
    evt,
    {
      customPopupSelector = '.dln-popup',
      hasReactContent = true,
      gigyaCallback = null,
      contentId,
      type,
      maxWidth,
      desktopAlign,
      mobileAlign,
      scrollOrigin,
      fullSize,
      animation,
      closeStyle,
    } = {},
  ) {
    const isAlert = type === 'alert';
    // const closeStyleBtn = closeStyle === 'circleClose' ? 'circleClose' : 'defaultClose';

    const evtPopup =
      evt &&
      evt.currentTarget &&
      evt.currentTarget.classList &&
      evt.currentTarget.classList.contains('dln-popup')
        ? evt.currentTarget
        : null;
    const popup = evtPopup || document.querySelectorAll(customPopupSelector)[0];
    const popupContent = popup.children[0];
    const closeBtn = popup.getElementsByClassName('dln-btnIcon--close')[0];
    const closeModal =
      evt &&
      ((evt.target === closeBtn &&
        (evt.type === 'click' ||
          evt.code === 'Escape' ||
          evt.code === 'Space')) ||
        (evt.target === popup && evt.target !== popupContent) ||
        (evt.type !== 'click' && evt.key === 'Escape'));
    const isOpen = popup.classList.contains('dln-popup--open');

    if (closeModal) {
      evt.stopPropagation();
      ModalController.destroyModal(popup);
      if (modalTriggerBtn) {
        modalTriggerBtn.focus();
      }
    } else if (!isOpen) {
      modalTriggerBtn = document.activeElement;

      if (isAlert) {
        document
          .getElementsByTagName('body')[0]
          .classList.add('dln-popup--open', 'dln-popup--openAlert');
      } else {
        document
          .getElementsByTagName('body')[0]
          .classList.add('dln-popup--open');
      }

      /* Condition for Not React Components */
      if (!hasReactContent) {
        const contentNodes = document.getElementById(contentId).childNodes;
        for (let index = 0; index < contentNodes.length; index += 1) {
          const cln = contentNodes[index].cloneNode(true);
          popup
            .getElementsByClassName('dln-popup__wrapper')[0]
            .appendChild(cln);
        }
      }
      /* end */
      // this callback attach screenset from gigya when a modal is required
      if (gigyaCallback) gigyaCallback();

      if (closeStyle === 'circleClose') {
        popup.classList.add('dln-popup--circleClose');
      }

      if (isAlert) popup.classList.add('dln-popup--alert');
      popup.setAttribute('aria-hidden', 'false');
      popup.style.overflow = 'hidden';
      popup.style.overflowY = 'hidden';
      popup.style.display = 'flex';
      if (closeBtn) {
        closeBtn.blur();
        if (modalTriggerBtn) {
          modalTriggerBtn.focus();
        }
      }
      ModalController.initModal({
        popup,
        type,
        maxWidth,
        desktopAlign,
        mobileAlign,
        scrollOrigin,
        fullSize,
        animation,
      });
      setTimeout(() => {
        popup.classList.add('dln-popup--visible');
        popup.classList.add('dln-popup--open');
        closeBtn.focus();

        /* Action for Library preview only */
        // popup.getElementsByClassName('dln-popup__wrapper')[0].innerHTML = '';
        /* End */
      }, 300);
    }
  }
}

export default ModalController;
