import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import PageLoading from '@/account/pages/PageLoading';
import { I18nextProvider } from 'react-i18next';
import i18n from '@/i18n';

class SearchFormAndModalLoader {
  static init() {
    SearchFormAndModalLoader.initForm();
    SearchFormAndModalLoader.initModal();
  }

  static initForm() {
    const formEntry = document.getElementById('js-r-entry-search-form');
    const modalEntry = document.getElementById('js-r-entry-search-modal');
    if (formEntry && modalEntry) {
      const SearchFormLazy = Loadable({
        loader: () =>
          import(
            /* webpackChunkName: "search-form" */ '@/components/organisms/Search/SearchForm'
          ),
        loading: PageLoading,
      });
      i18n.loadNamespaces(['search'], () => {
        ReactDOM.render(
          <I18nextProvider i18n={i18n}>
            <Suspense fallback={<div />}>
              <SearchFormLazy {...modalEntry.dataset} {...formEntry.dataset} />
            </Suspense>
          </I18nextProvider>,
          formEntry,
        );
      });
    }
  }

  static initModal() {
    const ctas = document.querySelectorAll('.js-search-modal');
    if (ctas) {
      Array.from(ctas).forEach(cta => {
        cta.addEventListener('click', SearchFormAndModalLoader.load);
      });
    }
  }

  static load() {
    const entry = document.getElementById('js-r-entry-search-modal');
    if (entry) {
      const quickLinks = entry.innerHTML;
      const SearchModalLazy = Loadable({
        loader: () =>
          import(
            /* webpackChunkName: "search-modal" */ '@/components/organisms/Search/Modal/SearchModal'
          ),
        loading: PageLoading,
      });
      i18n.loadNamespaces(['listing', 'search'], () => {
        ReactDOM.render(
          <I18nextProvider i18n={i18n}>
            <Suspense fallback={<div />}>
              <SearchModalLazy {...entry.dataset} quickLinks={quickLinks} />
            </Suspense>
          </I18nextProvider>,
          document.querySelector(
            '#js-default-dln-popup #js-dln-popup__wrapper',
          ),
        );
      });
    }
  }
}

export default SearchFormAndModalLoader;
