import EventEmitter from 'eventemitter3';
import 'lazysizes';
import lozad from 'lozad';
import 'regenerator-runtime/runtime';

import '@/style/Main.scss';

import { ajaxLoadingSpinner } from '@/components/AjaxLoadingSpinner';
import CountrySelectionPopupLoader from '@/components/CountrySelectionPopupLoader';
import { currencyConverter } from '@/components/CurrencyConverter';
import NewsletterPopupLoader from '@/components/NewsletterPopupLoader';
import { notificationCenter } from '@/components/NotificationCenter';
import SearchFormAndModalLoader from '@/components/SearchFormAndModalLoader';
import { detectMsBrowser } from '@/utils/Common/DetectIE';
import CartController from '@/utils/Controllers/CartController';
import GoogleTagManager from '@/utils/Controllers/GTM/GoogleTagManager';
import GigyaBasicFormController from '@/utils/Controllers/Gigya/GigyaBasicFormController';
import GigyaController from '@/utils/Controllers/Gigya/GigyaController';
import GigyaRegistrationCompletionChangeCountryController from '@/utils/Controllers/Gigya/GigyaRegistrationCompletionChangeCountryController';
import { unsubscribeController } from '@/utils/Controllers/Gigya/GigyaUnsubscribeController';
import HatchController from '@/utils/Controllers/HatchController';
import LanguageSelectorController from '@/utils/Controllers/LanguageSelectorController';
import ModalController from '@/utils/Controllers/ModalController';
import NotifyMeController from '@/utils/Controllers/NotifyMeController';
import RecipeController from '@/utils/Controllers/Recipes';
import SmarteditController from '@/utils/Controllers/SmarteditController';
import SnapEngageController from '@/utils/Controllers/SnapEngageController';
import StripeController from '@/utils/Controllers/StripeController';
import WishlistController from '@/utils/Controllers/WishlistController';
import MiniAccountController from '@/utils/Controllers/MiniAccountController';
import MiniCustomerSupportController from '@/utils/Controllers/MiniCustomerSupportController';

// Event Emitter
DLG.EVE = new EventEmitter();

// GTM
GoogleTagManager();

SnapEngageController.init();

// IE/Edge classification
const whatIE = detectMsBrowser();
if (whatIE === 'isIE' || whatIE === 'isEdge') {
  document.querySelector(`html`).className += ` ${whatIE}`;
}

document.addEventListener('DOMContentLoaded', () => {
  ajaxLoadingSpinner.init();
  notificationCenter.init();
  ModalController.init();
  SmarteditController.init();
  GigyaRegistrationCompletionChangeCountryController.init();
  CartController.init();
  HatchController.prefetch();
  WishlistController.init();
  NotifyMeController.init();
  StripeController.init();
  unsubscribeController.init();
  GigyaBasicFormController.init();
  GigyaController.handleLogout();
  LanguageSelectorController();
  SearchFormAndModalLoader.init();
  CountrySelectionPopupLoader.init();
  MiniAccountController();
  MiniCustomerSupportController();
  RecipeController();
  NewsletterPopupLoader.init();
  currencyConverter.init();

  // smartedit rebind
  SmarteditController.addOnReprocessPageListener(
    SearchFormAndModalLoader.initModal,
  );
});

/* window.addEventListener('load', () => {
  setTimeout(() => LazyLoadVideosController(), 1000);
}); */

window.addEventListener('load', () => {
  const observer = lozad(); // lazy loads elements with default selector as '.lozad'
  observer.observe();
});
