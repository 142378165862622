import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { I18nextProvider } from 'react-i18next';
import i18n from '@/i18n';
import FindParentByClass from '@/utils/Common/FindParentByClass';
import PageLoading from '@/account/pages/PageLoading';
import { getActualBrand } from '@/utils/Common/Utils';

// import api
import apiGetHatch, { prefetchHatch } from '@/api/get/hatch';

const HATCH_CLASS = 'js-where-to-buy';
const ACTUAL_BRAND = getActualBrand();

export default class HatchController {
  static prefetch() {
    const entries = Array.from(document.querySelectorAll(`.${HATCH_CLASS}`));
    entries.forEach(entry => {
      const { productCode } = entry.dataset;
      const successCB = hideHatch => {
        entry.style.display = hideHatch ? 'none' : entry.style.display;
        if (!hideHatch) {
          document.addEventListener(
            'click',
            HatchController.bindAllWhereToBuyCtas,
          );
        }
      };
      prefetchHatch({ productCode, successCB });
    });
  }

  static bindAllWhereToBuyCtas(evt) {
    const whereToBuyCta = FindParentByClass(evt.target, HATCH_CLASS);
    if (whereToBuyCta) {
      const { productCode } = whereToBuyCta.dataset;
      apiGetHatch({ productCode, successCB: HatchController.handleHatchPopup });
    }
  }

  static handleHatchPopup(data) {
    const retailers =
      data.iceleads_interface &&
      data.iceleads_interface.retailers &&
      data.iceleads_interface.retailers.retailer;
    const HatchModalLazy = Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "popup-add-to-cart" */ `@/components/popup/Hatch/${ACTUAL_BRAND}/HatchModal`
        ),
      loading: PageLoading,
    });

    i18n.loadNamespaces('hatch', () => {
      ReactDOM.render(
        <I18nextProvider i18n={i18n}>
          <HatchModalLazy retailers={retailers} />
        </I18nextProvider>,
        document.getElementById('js-dln-popup__wrapper'),
      );
    });
  }
}
