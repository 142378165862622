import {
  initCarousel,
  initCarouselMobileOnly,
} from '@/utils/Controllers/CarouselController';

const initRecipeCarousel = () => {
  const carousels = Array.from(
    document.querySelectorAll('.js-recipe-carousel'),
  );
  carousels.forEach(carousel => {
    initCarousel(carousel, {
      slidesPerViewXl: 4,
      slidesPerViewL: 4,
      slidesPerViewM: 3,
      slidesPerViewXs: 2,

      spaceBetweenXl: 20,
      spaceBetweenL: 20,
      spaceBetweenM: 20,
      spaceBetweenXs: 10,

      hideScrollbar: false,
    });
  });
};

const initAttachmentsCarousel = () => {
  const carousels = Array.from(
    document.querySelectorAll('.js-recipe-attachments-carousel'),
  );
  carousels.forEach(carousel => {
    initCarousel(carousel, {
      slidesPerViewXl: 3,
      slidesPerViewL: 3,
      slidesPerViewM: 3,
      slidesPerViewXs: 2,

      spaceBetweenXl: 10,
      spaceBetweenL: 10,
      spaceBetweenM: 10,
      spaceBetweenXs: 10,

      hideScrollbar: false,
    });
  });
};

const initHowToCarousel = () => {
  const carousels = Array.from(
    document.querySelectorAll('.js-recipe-how-to-carousel'),
  );
  carousels.forEach(carousel => {
    initCarouselMobileOnly(carousel, {
      slidesPerViewXs: 'auto',
      spaceBetweenXs: 20,
      hideScrollbar: false,
    });
  });
};

export const initRecipeSlider = () => {
  const recipeBoxSliders = [
    ...document.getElementsByClassName('recipe-slides'),
  ];

  recipeBoxSliders.forEach(recipeBoxSlider => {
    initCarousel(recipeBoxSlider, {
      slidesPerViewXL: 'auto',
      slidesPerViewL: 'auto',
      slidesPerViewM: 'auto',
      slidesPerViewXS: 'auto',

      spaceBetweenXL: 20,
      spaceBetweenL: 20,
      spaceBetweenM: 20,
      spaceBetweenXS: 10,

      hideScrollbar: false,
      loop: false,
    });

    const slides = [...recipeBoxSlider.querySelectorAll('.dln-recipe')];
    if (slides.length > 1) {
      recipeBoxSlider
        .querySelector('.swiper-footer')
        .classList.remove('-hidden');
    } else {
      recipeBoxSlider.querySelector('.swiper-footer').classList.add('-hidden');
    }
  });
};

export const handleRecipeTitleHeight = (isResizeEvent = false) => {
  const recipeSliders = [...document.querySelectorAll('.dln-recipe-slides')];

  recipeSliders.forEach(slider => {
    const titles = [...slider.querySelectorAll('.dln-recipe__title')];

    if (isResizeEvent) {
      titles.forEach(title => {
        title.style.minHeight = 'unset';
      });
    }

    const heightArr = [];

    titles.forEach(title => {
      heightArr.push(title.offsetHeight);
    });

    const minHeight = Math.max(...heightArr);

    titles.forEach(title => {
      title.style.minHeight = `${minHeight}px`;
    });
  });
};

export default () => {
  initRecipeCarousel();
  initAttachmentsCarousel();
  initHowToCarousel();
  initRecipeSlider();
  handleRecipeTitleHeight();
  window.addEventListener('resize', () => handleRecipeTitleHeight(true));
};
