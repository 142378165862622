import _includes from 'lodash/includes';
import _startsWith from 'lodash/startsWith';
import GTM from '@/utils/Controllers/GTM/Constants';
import { push } from '@/utils/Controllers/GTM/Utils';

export const pushGtmDownload = payload => {
  push({
    event: GTM.EVENT.DOWNLOAD,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.DOWNLOAD,
    eventLabel: payload,
  });
};

const downloadGtmHandler = evt => {
  const { target } = evt;
  const isAnchor = target.tagName.toLowerCase() === 'a';
  if (isAnchor) {
    const hasValidHref = !_startsWith(target.href, '#');
    const hasDownload = target.hasAttribute('download');
    const hasMedias = _includes(target.href, '/medias/');
    if (hasValidHref && (hasDownload || hasMedias)) {
      pushGtmDownload(target.href);
    }
  }
};

export default () => {
  document.addEventListener('click', downloadGtmHandler);
};
