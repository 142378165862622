import RecipeAccordionController from '@/utils/Controllers/Recipes/RecipeAccordionController';
import RecipeCarouselController from '@/utils/Controllers/Recipes/RecipeCarouselController';
import RecipeVideoBanner from '@/utils/Controllers/Recipes/RecipeVideoBanner';
// import RecipeFavController from '@/utils/Controllers/Recipes/RecipeFavController';
import RecipeUpdateResultsController from '@/utils/Controllers/Recipes/RecipeUpdateResultsController';
import RecipeBrowseByController from '@/utils/Controllers/Recipes/RecipeBrowseByController';

export default () => {
  RecipeAccordionController();
  RecipeCarouselController();
  RecipeVideoBanner();
  // RecipeFavController.init();
  RecipeUpdateResultsController();
  RecipeBrowseByController();
};
