import EVENTS from '@/utils/Constants/Events';
import { isTrue } from '@/utils/Common/Booleans';

const observerConfig = { rootMargin: '0px 0px 400px 0px' };

const WRAPPER_ID = 'js-recipe-grid';
const TILE_CLASS = 'js-dln-recipe';
const LIMIT_WRAPPER_ID = 'js-search-recipe-page-limit';
const RECIPE_LOAD_MORE_ID = 'js-recipe-load-more';

const updatePagination = nextPage => {
  DLG.EVE.emit(EVENTS.RECIPE.RESULTS.PAGINATION, { nextPage });
};

const loadMoreClickHandler = e => {
  const { nextPage } = e.currentTarget.dataset;
  updatePagination(nextPage);
};

const loadMoreResults = () => {
  const el = document.getElementById(RECIPE_LOAD_MORE_ID);
  if (el) {
    el.addEventListener('click', loadMoreClickHandler);
  }
};

const observerHandler = entries => {
  const entry = entries[0];
  const { nextPage } = entry.target.dataset;
  if (entry.isIntersecting) {
    updatePagination(nextPage);
  }
};

const infiniteScrollResults = () => {
  const el = document.getElementById(LIMIT_WRAPPER_ID);
  if (el && !isTrue(el.dataset.hasLoadMore)) {
    const observer = new IntersectionObserver(observerHandler, observerConfig);
    observer.observe(el);
  }
};

const bindAll = () => {
  infiniteScrollResults();
  loadMoreResults();
};

const updateResults = ({ html }) => {
  const el = document.getElementById(WRAPPER_ID);
  const children = el.querySelectorAll(`.${TILE_CLASS}`);
  Array.from(children).forEach(child => el.removeChild(child));
  el.innerHTML = html;
  bindAll();
};

const appendResults = ({ html }) => {
  const el = document.getElementById(WRAPPER_ID);
  const listingLimitEl = document.getElementById(LIMIT_WRAPPER_ID);
  listingLimitEl.insertAdjacentHTML('beforebegin', html);
  el.removeChild(listingLimitEl);
  bindAll();
};

export default () => {
  DLG.EVE.on(EVENTS.RECIPE.RESULTS.UPDATE, updateResults);
  DLG.EVE.on(EVENTS.RECIPE.RESULTS.APPEND, appendResults);
  bindAll();
};
