export const detectMsBrowser = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const trident = ua.indexOf('Trident/');
  const edge = ua.indexOf('Edge');
  if (msie > 0 || trident > 0) {
    return 'isIE';
  }
  if (edge > 0) {
    return 'isEdge';
  }
  return 'notIE';
};

export default {};
