import { notifyError } from '@/utils/Common/Notify';

// import api
import gigyaUnsubscribe from '@/api/post/gigyaUnsubscribe';

export default class GigyaUnsubscribeController {
  constructor() {
    this.unsubscribeButton = null;
    this.unsubscribeContent = null;
    this.unsubscribeContentSMS = null;
    this.unsubscribeContentSuccess = null;
    this.updatePreferencesButton = null;
    this.smsActive = null;
    this.unsubscribeEmailCheckbox = null;
    this.unsubscribeSmsCheckbox = null;
  }

  init() {
    this.unsubscribeButton = document.getElementById('js-btn-unsubscribe');
    this.unsubscribeContent = document.getElementById('js-unsubscribe-content');
    this.unsubscribeContentSMS = document.getElementById(
      'js-unsubscribe-content-sms',
    );
    this.unsubscribeContentSuccess = document.getElementById(
      'js-success-unsubscribe',
    );
    this.updatePreferencesButton = document.getElementById(
      'js-btn-update-preferences',
    );
    this.unsubscribeEmailCheckbox = document.querySelector(
      '.js-unsubscribe-email-checkbox',
    );
    this.unsubscribeSmsCheckbox = document.querySelector(
      '.js-unsubscribe-sms-checkbox',
    );

    if (this.unsubscribeButton) {
      this.unsubscribeButton.addEventListener('click', this.unsubscribe);
    }

    if (this.updatePreferencesButton) {
      this.updatePreferencesButton.addEventListener(
        'click',
        this.updatePreferences,
      );
    }

    // get the locale
    const lang = gigya.thisScript.lang.originalLang;
    // get the country code
    const countryCode = gigya.thisScript.globalConf.countryCodeMapping[lang];
    // if country prefix then SMS Channel is enabled
    const countryPrefix =
      gigya.thisScript.globalConf.phonePrefixMapping[countryCode];
    // Only active for Delonghi brand
    this.smsActive = !!countryPrefix && DLG.config.brand === 'delonghi';

    // Choose the UI to show
    if (
      this.smsActive &&
      (this.unsubscribeEmailCheckbox || this.unsubscribeSmsCheckbox)
    ) {
      this.unsubscribeContentSMS.removeAttribute('hidden');
    } else if (!this.smsActive && this.unsubscribeContent) {
      this.unsubscribeContent.removeAttribute('hidden');
    }
  }

  handleUnsubscribeRequest({ gigyaUID, email, emailChannel, smsChannel }) {
    const successCB = res => {
      if (res.code === 500) {
        notifyError(res.message);
      } else {
        this.unsubscribeContentSuccess.removeAttribute('hidden');
        this.unsubscribeContent.setAttribute('hidden', 'true');
        this.unsubscribeContentSMS.setAttribute('hidden', 'true');
      }
    };

    // get the locale
    const lang = gigya.thisScript.lang.originalLang;
    // get the country code
    const countryCode = gigya.thisScript.globalConf.countryCodeMapping[lang];
    // get the consent field related to the country code
    const siteConsent = gigya.thisScript.globalConf.consentMapping[countryCode];

    gigyaUnsubscribe({
      info: {
        gigyaUID,
        lang,
        siteConsent,
        countryCode,
        email,
        emailChannel,
        smsChannel,
      },
      successCB,
    });
  }

  unsubscribe = e => {
    e.preventDefault();
    const gigyaUID = this.unsubscribeButton.getAttribute('data-gigya-uid');
    const email = this.unsubscribeButton.getAttribute('data-email');
    // True means you want to unsubscribe
    const emailChannel = true;
    // SMS Channel is disabled on this country so we just send false
    const smsChannel = false;

    this.handleUnsubscribeRequest({
      gigyaUID,
      email,
      emailChannel,
      smsChannel,
    });
  };

  updatePreferences = e => {
    e.preventDefault();
    const gigyaUID = this.updatePreferencesButton.getAttribute(
      'data-gigya-uid',
    );
    const email = this.updatePreferencesButton.getAttribute('data-email');
    // The value of checkbox when it is check is false for the request
    // When there's no checkbox then it's true
    const emailChannel = !this.unsubscribeEmailCheckbox?.checked;
    const smsChannel = !this.unsubscribeSmsCheckbox?.checked;

    this.handleUnsubscribeRequest({
      gigyaUID,
      email,
      smsChannel,
      emailChannel,
    });
  };
}

export const unsubscribeController = new GigyaUnsubscribeController();
