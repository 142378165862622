import {
  isBraun,
  isDelonghi,
  isKenwood,
  isNutribullet,
} from '@/utils/Common/Booleans';

export default () => {
  let elId = null;
  let triggerSelector = null;

  if (isBraun) {
    elId = 'js-r-entry-braun-miniaccount';
    triggerSelector = '.bra-btnIcon__wrap';
  } else if (isDelonghi || isNutribullet) {
    elId = 'js-r-entry-del-miniaccount';
    triggerSelector = 'a[type=button]';
  } else if (isKenwood) {
    elId = 'js-r-entry-ken-miniaccount';
    triggerSelector = 'a[type=button]';
  }

  if (elId === null || triggerSelector === null) {
    return;
  }

  const el = document.getElementById(elId);

  if (el && el.hasChildNodes()) {
    el.addEventListener('mouseover', () => {
      el.classList.add('hover');
    });

    el.addEventListener('mouseleave', () => {
      el.classList.remove('hover');
    });

    const button = el.querySelector(triggerSelector);
    const drawer = el.querySelector('.dln-drawer');

    if (button && drawer) {
      button.addEventListener('keydown', e => {
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          e.stopPropagation();
          el.classList.add('hover');
          drawer.focus();
        } else if (e.key === 'ArrowUp' || e.key === 'Escape') {
          e.preventDefault();
          e.stopPropagation();
          el.classList.remove('hover');
          button.focus();
        } else if (e.key === ' ') {
          e.preventDefault();
          e.stopPropagation();
          el.classList.toggle('hover');
          (el.classList.contains('hover') ? drawer : button).focus();
        }
      });

      drawer.addEventListener('keydown', e => {
        if (e.key === 'Escape') {
          el.classList.remove('hover');
          button.focus();
        }
      });
    }
  }
};
