import { POST } from '@/api';

const URL = '/wishlist/notification';

export default async ({ productCodePost, config, successCB, failCB }) => {
  try {
    const { data } = await POST(URL, { productCodePost }, config);
    return (typeof successCB === 'function' && successCB(data)) || data;
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};
