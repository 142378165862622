import NotificationItemMinimal from '@/components/molecules/NotificationItemMinimal';
import EVENTS from '@/utils/Constants/Events';
import CONSTANTS from '@/utils/Constants/General';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'uuid/v4';

const NotificationCenter = () => {
  const [notifications, setNotifications] = useState([]);
  const { t } = useTranslation();

  const removeNotification = id => {
    setNotifications(prevState =>
      prevState.filter(item => {
        if (item.id !== id) {
          return true;
        }
        clearTimeout(item.timeout);
        return false;
      }),
    );
  };

  const addNotification = useCallback((type, message) => {
    const id = uuid();
    const headline = t(`notification.${type}.headline`);
    const newNotification = {
      id,
      type,
      headline,
      ...message,
      timeout: setTimeout(
        () => removeNotification(id),
        CONSTANTS.NOTIFICATION.TIMEOUT,
      ),
    };
    setNotifications(prevState => [...prevState, newNotification]);
  }, []);

  const createNotifications = () =>
    notifications.map(item => {
      const { id, type, message } = item;

      return (
        <NotificationItemMinimal
          key={id}
          id={id}
          type={type}
          message={t(message)}
          onDelete={removeNotification}
        />
      );
    });

  useEffect(() => {
    DLG.EVE.on(EVENTS.NOTIFICATION.ADD.SUCCESS, message =>
      addNotification('success', message),
    );
    DLG.EVE.on(EVENTS.NOTIFICATION.ADD.ERROR, message =>
      addNotification('error', message),
    );
    DLG.EVE.emit(EVENTS.NOTIFICATION.MOUNTED);
  }, [addNotification]);

  return <div className='dln-notification-center'>{createNotifications()}</div>;
};

export default NotificationCenter;
