import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import EVENTS from '@/utils/Constants/Events';
import { I18nextProvider } from 'react-i18next';
import i18n from '@/i18n';
import NotificationCenterComponent from '@/components/organisms/NotificationCenter';
import { notifyError, notifySuccess } from '@/utils/Common/Notify';

class NotificationCenter {
  constructor() {
    this.entry = 'js-r-entry-notification-center';
    this.messagesContainer = 'js-global-messages-container';
    this.successMessagesEntry = 'js-global-message-success';
    this.errorMessagesEntry = 'js-global-message-error';
    this.successMessages = [];
    this.errorMessages = [];
  }

  init() {
    ReactDOM.render(
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={<div />}>
          <NotificationCenterComponent />
        </Suspense>
      </I18nextProvider>,
      document.getElementById(this.entry),
    );

    this.getInitialMessages();
    DLG.EVE.on(EVENTS.NOTIFICATION.MOUNTED, () => {
      this.emitMessages();
      this.deleteMessagesContainer();
    });
  }

  getInitialMessages() {
    this.errorMessages = NotificationCenter.getMessagesData(
      this.errorMessagesEntry,
    );

    this.successMessages = NotificationCenter.getMessagesData(
      this.successMessagesEntry,
    );
  }

  static getMessagesData(entry) {
    return Array.from(document.getElementsByClassName(entry)).map(
      errorMessage => errorMessage.dataset.value,
    );
  }

  deleteMessagesContainer() {
    const messagesContainer = document.getElementById(this.messagesContainer);
    if (messagesContainer) {
      messagesContainer.parentNode.removeChild(messagesContainer);
    }
  }

  emitMessages() {
    Array.from(this.successMessages).forEach(message => notifySuccess(message));
    Array.from(this.errorMessages).forEach(message => notifyError(message));
  }
}

export const notificationCenter = new NotificationCenter();

export default {};
