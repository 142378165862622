import { isKenwood } from '@/utils/Common/Booleans';

export default () => {
  const elId = isKenwood ? 'js-r-entry-ken-minicustomersupport' : null;
  const triggerSelector = isKenwood ? 'a[type=button]' : null;

  if (!elId || !triggerSelector) {
    return;
  }

  const el = document.getElementById(elId);

  if (el && el.hasChildNodes()) {
    el.addEventListener('mouseover', () => {
      el.classList.add('hover');
    });

    el.addEventListener('mouseleave', () => {
      el.classList.remove('hover');
    });

    const button = el.querySelector(triggerSelector);
    const drawer = el.querySelector('.dln-drawer');

    if (button && drawer) {
      button.addEventListener('keydown', e => {
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          e.stopPropagation();
          el.classList.add('hover');
          drawer.focus();
        } else if (e.key === 'ArrowUp' || e.key === 'Escape') {
          e.preventDefault();
          e.stopPropagation();
          el.classList.remove('hover');
          button.focus();
        } else if (e.key === ' ') {
          e.preventDefault();
          e.stopPropagation();
          el.classList.toggle('hover');
          (el.classList.contains('hover') ? drawer : button).focus();
        }
      });

      drawer.addEventListener('keydown', e => {
        if (e.key === 'Escape') {
          el.classList.remove('hover');
          button.focus();
        }
      });
    }
  }
};
