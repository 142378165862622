/* eslint-disable import/prefer-default-export */
import GTM from '@/utils/Controllers/GTM/Constants';
import { push } from '@/utils/Controllers/GTM/Utils';

const CLASS_NAME_SELECTOR = 'js-gtm-internal-search-form';

export const pushGtmInternalSearch = payload => {
  push({
    event: GTM.EVENT.INTERNAL_SEARCH,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.INTERNAL_SEARCH,
    eventLabel: payload,
  });
};

const handleFormSubmit = e => {
  const inputText = e.currentTarget.querySelector(`[name='text']`);
  pushGtmInternalSearch(inputText.value);
};

export default () => {
  const searchForm = document.querySelector(`.${CLASS_NAME_SELECTOR}`);
  if (searchForm) {
    searchForm.classList.remove(CLASS_NAME_SELECTOR);
    searchForm.addEventListener('submit', handleFormSubmit);
  }
};
