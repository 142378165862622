import React from 'react';
import ReactDOM from 'react-dom';
import AjaxLoadingSpinnerComponent from '@/components/organisms/AjaxLoadingSpinner';

class AjaxLoadingSpinner {
  constructor() {
    this.entry = 'js-r-entry-ajax-loading-spinner';
  }

  init() {
    ReactDOM.render(
      <AjaxLoadingSpinnerComponent />,
      document.getElementById(this.entry),
    );
  }
}

export const ajaxLoadingSpinner = new AjaxLoadingSpinner();

export default {};
